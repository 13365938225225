
@keyframes table-loading-row {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 0 0;
  }
}

.mui-fixed {
  box-sizing: content-box;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

u + p {
  display: inline;
}
